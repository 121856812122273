<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="mt-4" v-if="formState!==null">{{formState}} Record</span>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-autocomplete
          v-model="level"
          :items="[2,3,4]"
          outlined
          dense
          clearable
          item-text="title"
          item-value="id"
          label="Level"
          class="mb-3"
          :error-messages="formErrorFields.levelErrorMessage"
          disabled
          @change="formError().remove('level', formErrorFields)"
        ></v-autocomplete>

        <v-autocomplete
          v-model="positionCategory"
          :items="positionCategories"
          outlined
          dense
          clearable
          multiple
          :loading="positionCategoryLoading"
          item-text="title"
          item-value="id"
          label="Position Category"
          class="mb-3"
          :error-messages="formErrorFields.positionCategoryErrorMessage"
          :disabled="formState===null"
          @change="formError().remove('positionCategory', formErrorFields)"
        ></v-autocomplete>

        <v-btn
          color="primary"
          :disabled="formState===null"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="formState===null"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    >
    </snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPlusOutline } from '@mdi/js'
import crud from '@/composables/crud'
import get from '@/composables/get'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'

export default {
  props: ['data'],
  setup(props, { emit }) {
    const loading = ref(false)
    const positionCategoryLoading = ref(false)
    const message = ref('')
    const addIcon = { icon: mdiPlusOutline, name: 'mdiPlusOutline' }
    const level = ref('')
    const positionCategory = ref('')
    const positionCategories = ref([])
    const formState = ref(null)
    const formErrorFields = ref({
      positionCategoryErrorMessage: '',
    })

    watch(() => props.data, () => {
      level.value = props.data.level
      positionCategory.value = props.data.allowed_position_category.split(',')
      positionCategory.value = positionCategory.value.map(val => parseInt(val, 10))
      formState.value = 'Edit'
    })

    const {
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formState.value = null
      level.value = ''
      positionCategory.value = ''
    }

    const submit = async () => {
      const formData = {
        level: level.value,
        positionCategory: positionCategory.value,
      }

      const uri = `${process.env.VUE_APP_URI}/api/payroll/user-levels/${props.data.level}`
      await update(uri, loading, formData, formErrorFields)

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        emit('updateUniqueID')
        reset()
      }
    }

    get(`${process.env.VUE_APP_URI}/api/payroll/user-levels/position-category`, positionCategories, positionCategoryLoading)

    return {
      level,
      positionCategory,
      positionCategories,
      positionCategoryLoading,
      addIcon,
      formState,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
    }
  },

  components: {
    snackbar,
  },
}
</script>
