<template>
  <v-card class="elevation-1">
    <v-card-title>
      User Levels
      <v-row>
        <v-col cols="12" md="4" lg="4" offset-md="8" offset-lg="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
      class="mobile"
    >
      <template #[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          {{iconList.edit}}
        </v-icon>
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiDeleteOutline, mdiPencilOutline, mdiPlusOutline } from '@mdi/js'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'

export default {
  props: ['uniqueID'],
  setup(props, { emit }) {
    const search = ref('')
    const headers = ref([
      { text: 'Level', value: 'level' },
      { text: 'Position Category', value: 'allowed_position_category_text' },
      { text: 'Action', value: 'actions' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const iconList = {
      add: mdiPlusOutline,
      edit: mdiPencilOutline,
      delete: mdiDeleteOutline,
    }

    const { responseMessageStatus, responseMessage } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/payroll/user-levels/masterlist`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(() => props.uniqueID, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    const editItem = item => {
      emit('passData', item)
    }

    return {
      search,
      headers,
      items,
      searchTable,
      iconList,
      options,
      totalItems,
      loading,
      editItem,
      snackbar,
      responseMessageStatus,
      responseMessage,
    }
  },

  components: {
    snackbar,
  },
}
</script>
